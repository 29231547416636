import React from 'react';
import DashboardLayout from "../layout/DashboardLayout";
import NodeDetails from "../components/NodeDetails/NodeDetails";

const NodeView = () => {
    const content = <NodeDetails/>;

    const title = "Node";

    return <DashboardLayout section={'Node'} title={title} content={content}/>
};

export default NodeView;
