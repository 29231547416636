import React, {useEffect, useState} from 'react';
import {Pie} from '@ant-design/plots';

const TaskTypePieChart = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data)
    }, []);

    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return <div>
        <h1>Task Types</h1>
        <Pie {...config} />
    </div>;
};

export default TaskTypePieChart;
