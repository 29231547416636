import React, {ReactElement, useEffect, useState} from 'react';
import {Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {colorMap} from "../../../helper/colormap";

interface DataType {
    key: string;
    attribute: string;
    value: string | number;
}

interface Info {
    id: number;
    createdTime: string;
    numTasks: number;
    state: 'FAIL' | 'RUNNING' | 'PENDING';
}

interface Props {
    info: Info
}

const WorkflowInfoTable = (props: Props) => {
    const [data, setData] = useState(null);

    const row = (attribute: string, value: string | number, element?: ReactElement) => {
        return {
            ket: attribute,
            attribute: attribute,
            value: value,
            element: element ? element : <span>{value}</span>,
        }
    };

    const stateTag = (state: 'FAIL' | 'RUNNING' | 'PENDING') => {
        let color = colorMap[state];
        return <Tag color={color} key={state}>{state}</Tag>;
    };

    const setupData = (info: Info) => {
        return [
            row('Id', info.id),
            row('Created Time', info.createdTime),
            row('Number of Tasks', info.numTasks),
            row('State', info.state, stateTag(info.state))
        ]
    };

    useEffect(() => {
        if (props.info) {
            setData(setupData(props.info));
        }
    }, [props.info]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Attribute',
            dataIndex: 'attribute',
            key: 'attribute',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (_, record) => {
                return record.element;
            }
        },
    ];

    return <Table columns={columns} dataSource={data} pagination={false}/>;
};

export default WorkflowInfoTable;