import React, {useEffect, useState} from "react";
import WorkflowGraph from "./components/Graph/WorkflowGraph";
import WorkflowTable from "./components/WorkflowTable";
import {Empty} from "antd";
import {Navigate, useParams} from "react-router";
import {getAllWorkflowInfo, getWorkflowDetails} from "../../api/api";
import WorkflowInfoTable from "./components/WorkflowInfoTable";

const WorkflowDetails = () => {
    const [topo, setTopo] = useState(null);
    const [info, setInfo] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [type, setType] = useState(null);

    const {id} = useParams();

    const pageType = (): 'index' | 'browse' | 'unknown' => {
        if (id === 'index') {
            return 'index';
        }
        if (!isNaN(Number(id))) {
            return 'browse';
        }
        return 'unknown';
    };

    const setupInfo = (info) => {
        info.createdTime = new Date(info.createdTime).toISOString();
    };

    const setupTopo = (topo) => {
        let nodes = topo.nodes;
        let edges = topo.edges;

        let inDegreeMap = new Map();

        for (let i = 0; i < nodes.length; i++) {
            nodes[i].label = `Task(id=${nodes[i].id})`
        }

        for (let i = 0; i < edges.length; i++) {
            let e = edges[i];
            inDegreeMap.set(e['target'], true);
        }

        for (let i = 0; i < nodes.length; i++) {
            let n = nodes[i];
            if (!inDegreeMap.get(n.id)) {
                edges.push({
                    source: 'root',
                    target: n.id
                })
            }
        }

        nodes.push({
            id: 'root',
            label: `Workflow(id=${id})`,
            style: {
                fill: '#ffd5b7',
                stroke: '#ffaa91'
            }
        });
    };

    const setupTableData = (data) => {
        data.map(e => {
            e.key = e.id;
            e.createdTime = new Date(e.createdTime).toISOString();
        });
    };

    useEffect(() => {
        let type = pageType();
        setType(type);
        switch (type) {
            case "browse":
                let wfId = Number(id);
                getWorkflowDetails(wfId, (res) => {
                    let topo = res.data.topo;
                    let info = res.data.info;
                    setupTopo(topo);
                    setupInfo(info);
                    setInfo(info);
                    setTopo(topo);
                });
                break;
            case "index":
                getAllWorkflowInfo((res) => {
                    let data = res.data;
                    setupTableData(data);
                    setTableData(data);
                });
                break;
            case "unknown":
                break;
        }
    }, [id]);

    const indexContent = () => {
        return <div>
            <h1>Workflow Table</h1>
            <WorkflowTable data={tableData}/>
        </div>
    };

    const infoTable = () => {
        return <div>
            <h1>Workflow Info</h1>
            {info && <WorkflowInfoTable info={info}/>}
            {!info && <Empty/>}
        </div>
    };

    const browser = () => {
        return <div>
            <h1>Workflow Browser</h1>
            {topo && <WorkflowGraph data={topo}/>}
            {!topo && <Empty/>}
        </div>
    };

    const detailsContent = () => {
        return <div>
            {infoTable()}
            {browser()}
        </div>
    };

    return <div style={{height: '100vh', overflow: 'hidden'}}>
        {type === 'index' && indexContent()}
        {type === 'browse' && detailsContent()}
        {type === 'unknown' && <Navigate to={"/details/workflow/index"}/>}
    </div>
};

export default WorkflowDetails;