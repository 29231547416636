import "@ant-design/flowchart/dist/index.css"

import React, {useEffect, useState} from 'react';
import {Bar, BarConfig} from '@ant-design/plots';
import {useNavigate} from "react-router";

const TaskQueueChart = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const navigate = useNavigate();

    const config: BarConfig = {
        data: data,
        isStack: true,
        xField: 'length',
        yField: 'node',
        seriesField: 'type',
        xAxis: {
            grid: {
                line: {
                    style: {
                        lineDash: [2, 1]
                    }
                }
            }
        },
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        onReady: (plot => {
            plot.on('element:click', (e) => {
                let data = e.data.data;
                let match = data.type.match(/id=(\d+)/);
                let id = match[1];
                navigate(`/details/task/${id}`);
            });
        })
    };
    return <div>
        <h1>Node Schedule Queue Chart</h1>
        <Bar {...config}/>
    </div>;
};

export default TaskQueueChart;
