import React, {useEffect, useState} from 'react'
import {Menu} from 'antd'
import type {Edge, Task} from "../../../../../../types/workflow";

const {SubMenu} = Menu;

interface Props {
    selectedId: 'root' | number,
    tasks: Array<Task>,
    edges: Array<Edge>,
    onAddTask: ('root' | number) => void,
    onModify: ('root' | number) => void,
    onDelete: (number) => void,
    onAddEdge: ('root' | number, number) => void,
}

const NodeContextMenu = (props: Props) => {
    const [ids: Array<string>, setIds] = useState([]);

    const existsEdge = (src: 'root' | number, tgt: number) => {
        let edges = props.edges;
        for (let i = 0; i < edges.length; i++) {
            let e = edges[i];
            if (e.source === src && e.target === tgt) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        let ids = props.tasks.filter(t => t.id !== props.selectedId && !existsEdge(props.selectedId, t.id))
            .map(n => n.id);
        setIds(ids);
    }, [props.selectedId]);

    const onAddTask = () => {
        props.onAddTask(props.selectedId);
    };

    const onModify = () => {
        props.onModify(props.selectedId);
    };

    const onDelete = () => {
        props.onDelete(props.selectedId);
    };

    const edgeItems = () => {
        return ids.length > 0 && <SubMenu key="new edge" title='New Edge'>{
            ids.map(id => (
                <Menu.Item key={`Task(id=${id})`} onClick={() => {
                    let src = props.selectedId;
                    if (src !== 'root') src = Number(src);
                    props.onAddEdge(src, Number(id));
                }}>{`Task(id=${id})`}</Menu.Item>
            ))
        }</SubMenu>
    };

    return <Menu style={{width: 120, position: 'absolute', left: props.x, top: props.y}} mode="vertical">
        <SubMenu key="operate" title={<span>Operate</span>}>
            <Menu.Item key="new task" onClick={onAddTask}>New Task</Menu.Item>
            {edgeItems()}
            {props.selectedId !== 'root' && <Menu.Item key="delete" onClick={onDelete}>Delete</Menu.Item>}
            {props.selectedId !== 'root' && <Menu.Item key="modify" onClick={onModify}>Modify</Menu.Item>}
        </SubMenu>
        <Menu.Item key="cancel" onClick={props.onCancel}>Cancel</Menu.Item>
    </Menu>
};

export default NodeContextMenu;