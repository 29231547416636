import React from 'react'
import {Menu} from 'antd'
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

const NodeContextMenu = (props) => {
    return <Menu style={{width: 120, position: 'absolute', left: props.x, top: props.y}} mode="vertical">
        <SubMenu key="operate" title={<span>Operate</span>}>
            <Menu.Item key="details"><Link to={`/details/node/${props.nid}`}>Browse detail</Link></Menu.Item>
        </SubMenu>
        <Menu.Item key="cancel" onClick={props.onCancel}>Cancel</Menu.Item>
    </Menu>
};

export default NodeContextMenu