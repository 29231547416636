import React, {useEffect, useState} from "react";
import {Empty} from "antd";
import {Navigate, useParams} from "react-router";
import {getAllTaskInfo, getTaskDetails} from "../../api/api";
import TaskTable from "./components/TaskTable";
import TaskInfoTable from "./components/TaskInfoTable";
import TaskConstraintsList from "../TaskConstraintsList";

const WorkflowDetails = () => {
    const [info, setInfo] = useState(null);
    const [constraints, setConstraints] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [type, setType] = useState(null);

    const {id} = useParams();

    const pageType = (): 'index' | 'browse' | 'unknown' => {
        if (id === 'index') {
            return 'index';
        }
        if (!isNaN(Number(id))) {
            return 'browse';
        }
        return 'unknown';
    };

    const setupTableData = (data) => {
        data.map(e => {
            e.key = e.id;
        });
    };

    useEffect(() => {
        let type = pageType();
        setType(type);
        switch (type) {
            case "browse":
                let tid = Number(id);
                getTaskDetails(tid, (res) => {
                    let info = res.data.info;
                    setInfo(info);
                    let constraints = res.data.constraints;
                    setConstraints(constraints);
                });
                break;
            case "index":
                getAllTaskInfo((res) => {
                    let data = res.data;
                    setupTableData(data);
                    setTableData(data);
                });
                break;
            case "unknown":
                break;
        }
    }, [id]);

    const indexContent = () => {
        return <div>
            <h1>Task Table</h1>
            <TaskTable data={tableData}/>
        </div>
    };

    const infoTable = () => {
        return <div>
            {info && <TaskInfoTable info={info}/>}
            {!info && <Empty/>}
        </div>
    };

    const constraintsList = () => {
        return <div>
            <h1>Constraints</h1>
            {constraints && <TaskConstraintsList data={constraints}/>}
            {!constraints && <Empty/>}
        </div>
    };

    const detailsContent = () => {
        return <div>
            {infoTable()}
            {constraintsList()}
        </div>
    };

    return <div style={{height: '100vh', overflow: 'hidden'}}>
        {type === 'index' && indexContent()}
        {type === 'browse' && detailsContent()}
        {type === 'unknown' && <Navigate to={"/details/task/index"}/>}
    </div>
};

export default WorkflowDetails;