import React, {useEffect, useState} from "react";
import {Empty} from "antd";
import {Navigate, useParams} from "react-router";
import {getAllNodeInfo, getNodeDetails, getNodeTopo} from "../../api/api";
import NodeTable from "./components/NodeTable";
import NodeTopography from "./components/Graph/NodeTopography";
import NodeInfoTable from "./components/NodeInfoTable";
import TaskQueueChart from "../Charts/TaskQueueChart";

const NodeDetails = () => {
    const [info, setInfo] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [topo, setTopo] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [type, setType] = useState(null);

    const {id} = useParams();

    const pageType = (): 'index' | 'browse' | 'unknown' => {
        if (id === 'index') {
            return 'index';
        }
        if (!isNaN(Number(id))) {
            return 'browse';
        }
        return 'unknown';
    };

    const setupTableData = (data) => {
        data.map(e => {
            e.key = e.id;
        });
    };

    const setupTopo = (topo) => {
        topo.nodes.map(e => {
            e.label = e.id;
            if (e.id === '0') {
                e.style = {
                    fill: '#ffd5b7',
                    stroke: '#ffaa91'
                }
            }
        });
    };

    const setupTasks = (tasks) => {
        return tasks.map((t) => {
            return {
                length: Math.ceil(t.computationDensity * t.inputDataSize + 0.5),
                node: `Node(id=${id})`,
                type: `Task(id=${t.id})`
            }
        });
    };

    useEffect(() => {
        let type = pageType();
        setType(type);
        switch (type) {
            case "browse":
                let tid = Number(id);
                getNodeDetails(tid, (res) => {
                    let info = res.data.info;
                    let tasks = res.data.tasks;
                    setInfo(info);
                    setTasks(setupTasks(tasks));
                });
                break;
            case "index":
                getAllNodeInfo((res) => {
                    let data = res.data;
                    setupTableData(data);
                    setTableData(data);
                });
                getNodeTopo((res) => {
                    let topo = res.data;
                    setupTopo(topo);
                    setTopo(topo);
                });
                break;
            case "unknown":
                break;
        }
    }, [id]);

    const indexContent = () => {
        return <div style={{
            WebkitUserSelect: 'none',
            msUserSelect: 'none'
        }}>
            <h1>Node Table</h1>
            <NodeTable data={tableData}/>
            <h1>Node Topography</h1>
            <NodeTopography data={topo}/>
        </div>
    };

    const infoTable = () => {
        return <div>
            {info && <NodeInfoTable info={info}/>}
            {!info && <Empty/>}
        </div>
    };

    const detailsContent = () => {
        return <div>
            {infoTable()}
            <TaskQueueChart data={tasks}/>
        </div>
    };

    return <div>
        {type === 'index' && indexContent()}
        {type === 'browse' && detailsContent()}
        {type === 'unknown' && <Navigate to={"/details/task/index"}/>}
    </div>
};

export default NodeDetails;