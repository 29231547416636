export function get(url: string, callback: Function, onError?: Function) {
    let opts: RequestInit = {
        method: "GET",
    };
    fetch(url, opts)
        .then(response => response.json())
        .then(data => {
            callback(data);
        })
        .catch(error => {
            console.log(error);
            if (onError !== undefined)
                onError(error);
        });
}

export async function asyncGet(url: string, callback: Function, onError?: Function) {
    let opts: RequestInit = {
        method: "GET",
    };
    return fetch(url, opts)
        .then(response => response.json());
}

export function post(url: string, body: any, callback: Function, onError?: Function) {
    let opts = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include" //to upload cookies from client
    };

    fetch(url, opts)
        .then(response => response.json())
        .then(data => {
            callback(data);
        })
        .catch(error => {
            console.log(error);
            if (onError !== undefined)
                onError(error);
        });
}
