import {Card, Table, Tag, Row, Col} from "antd";
import React, {useEffect, useState} from "react";
import Progress from "antd/es/progress";
import {colorMap} from "../../../../../helper/colormap";

interface TaskBrief {
    id: number,
    workflowId: number,
    inputDataSize: number,
    type: number,
    computationDensity: number,
    dataCompressRatio: number,
    percent: number,
    state: 'PENDING' | 'RUNNING' | 'FAIL',
}

interface Props {
    x: number,
    y: number,
    brief: TaskBrief
}

const NodeTooltips = (props: Props) => {
    const [brief, setBrief] = useState(null);

    useEffect(() => {
        if (props.brief) {
            setBrief(props.brief);
        }
    }, [props.brief]);

    const formatPercent = (percent: number) => {
        if (percent >= 0 && percent <= 1) {
            return percent * 100;
        }
        return Math.min(100, Math.max(percent, 0));
    };

    const stateTag = (state: 'PENDING' | 'RUNNING' | 'FAIL') => {
        return <Tag color={colorMap[state]} key={state}>
            {state}
        </Tag>
    };

    const infoRow = (title: string, content) => {
        return <Row>
            <Col span={21}>{title}</Col>
            <Col span={3}>{content}</Col>
        </Row>
    };

    return <div style={{position: 'absolute', top: `${props.y}px`, left: `${props.x}px`}}>
        {brief && <Card title={`Task(id=${brief.id})`} bordered={false} style={{width: '300px'}}>
            {infoRow('Workflow id', brief.workflowId)}
            {infoRow('Type', brief.type)}
            {infoRow('Input data size', brief.inputDataSize)}
            {infoRow('Computation density', brief.computationDensity)}
            {infoRow('Data compress ratio', brief.dataCompressRatio)}
            {infoRow('Percent', <Progress percent={formatPercent(brief.percent)}/>)}
            {infoRow('State', stateTag(brief.state))}
        </Card>}
    </div>;
};

export default NodeTooltips;