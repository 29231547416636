import React, {useEffect, useState} from 'react';
import {Descriptions, Tag} from 'antd';
import {colorMap} from "../../../helper/colormap";
import {Link} from "react-router-dom";
import Progress from "antd/es/progress";

interface Info {
    id: number,
    workflowId: number,
    type: number,
    nodeId: number,
    inputDataSize: number,
    computationDensity: number,
    dataCompressRatio: number,
    percent: number,
    state: 'FAIL' | 'RUNNING' | 'PENDING'
}

interface Props {
    info: Info
}

const TaskInfoTable = (props: Props) => {
    const [data, setData] = useState(null);

    const stateTag = (state: 'FAIL' | 'RUNNING' | 'PENDING') => {
        let color = colorMap[state];
        return <Tag color={color} key={state}>{state}</Tag>;
    };

    const formatPercent = (percent: number) => {
        if (percent >= 0 && percent <= 1) {
            return percent * 100;
        }
        return Math.min(100, Math.max(percent, 0));
    };

    useEffect(() => {
        if (props.info) {
            setData(props.info);
        }
    }, [props.info]);

    return <div>
        {data &&
        <Descriptions title="Task Info" layout="vertical" bordered
                      column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}>
            <Descriptions.Item label="Id">{data.id}</Descriptions.Item>
            <Descriptions.Item label="Type">{data.type}</Descriptions.Item>
            <Descriptions.Item label="Workflow Id">
                <Link to={`/details/workflow/${data.workflowId}`}>{data.workflowId}</Link>
            </Descriptions.Item>
            <Descriptions.Item label="Node Id">
                <Link to={`/details/node/${data.nodeId}`}>{data.nodeId}</Link>
            </Descriptions.Item>
            <Descriptions.Item label="State">
                {stateTag(data.state)}
            </Descriptions.Item>
            <Descriptions.Item label="Input Data Size">{data.inputDataSize}</Descriptions.Item>
            <Descriptions.Item label="Computation Density">{data.computationDensity}</Descriptions.Item>
            <Descriptions.Item label="Data Compress Ratio">{data.dataCompressRatio}</Descriptions.Item>
            <Descriptions.Item label="Percent">
                <Progress percent={formatPercent(data.percent)}/>
            </Descriptions.Item>
        </Descriptions>}
    </div>;
};

export default TaskInfoTable;