import {Button, Form, Input, InputNumber, Modal, Select, Space} from "antd";
import React from "react";
import type {Task} from "../../../../../../types/workflow";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";

const {Option} = Select;

interface Props {
    task: Task,
    onSubmit: (Task) => void,
    onCancel: () => void,
}

const ModifyModal = (props: Props) => {
    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields()
            .then(values => {
                props.onSubmit({...props.task, ...values});
            }).catch(err => {
            console.log(err);
        });
    };

    const operators = ['=', '<', '>', '<=', '>=', '!='];

    const initialValues = {
        inputDataSize: props.task.inputDataSize,
        dataCompressRatio: props.task.dataCompressRatio,
        computationDensity: props.task.computationDensity,
        constraints: props.task.constraints,
    };

    const constraintList = () => (
        <Form.List name="constraints">
            {(fields, {add, remove}) => (
                <>
                    {fields.map((field) => (
                        <Space key={field.key} direction="horizontal" align="baseline">
                            <Form.Item
                                {...field}
                                label="Attribute"
                                name={[field.name, 'attribute']}
                                rules={[{required: true, message: 'Missing attribute'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                noStyle
                            >
                                <Form.Item
                                    {...field}
                                    label="Op"
                                    name={[field.name, 'op']}
                                    rules={[{required: true, message: 'Missing attribute'}]}
                                >
                                    <Select
                                        style={{
                                            width: 130,
                                        }}
                                    >
                                        {operators.map((op) => (
                                            <Option key={op} value={op}>
                                                {op}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                {...field}
                                label="Value"
                                name={[field.name, 'value']}
                                rules={[{required: true, message: 'Missing value'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                {...field}
                                label="Args"
                                name={[field.name, 'args']}
                            >
                                <Input/>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                        </Space>
                    ))}

                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                            Add constraint
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );

    const renderForm = () => (
        <Form form={form} layout='horizontal' initialValues={initialValues}>
            <Form.Item name="inputDataSize" label="Input Data Size"
                       defa
                       rules={[{required: true, message: "Please input task's input data size"}]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item label="Workflow Id" disabled>
                {props.task.workflowId}
            </Form.Item>
            <Form.Item name="computationDensity" label="Computation Density"
                       rules={[{required: true, message: "Please input task's computation density"}]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item name="dataCompressRatio" label="Data Compress Ratio"
                       rules={[{required: true, message: "Please input task's data compress ratio"}]}>
                <InputNumber/>
            </Form.Item>

            <h3>Constraints</h3>
            {constraintList()}
        </Form>
    );

    return <Modal title={`Modify Task(id=${props.task.id})`} open onOk={onSubmit} onCancel={props.onCancel}
                  okText='Submit' width={"1000px"}>
        {renderForm()}
    </Modal>;
};

export default ModifyModal;