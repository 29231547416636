import React, {useEffect, useState} from 'react';
import {Space, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link} from "react-router-dom";
import {colorMap} from "../../../helper/colormap";

interface DataType {
    key: string;
    id: number;
    createdTime: string;
    numTasks: number;
    state: 'FAIL' | 'RUNNING' | 'PENDING';
}

const WorkflowTable = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Created Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
        },
        {
            title: 'Number of Tasks',
            dataIndex: 'numTasks',
            key: 'numTasks',
        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            render: (_, record) => {
                let state = record.state;
                let color = colorMap[state];
                return <Tag color={color} key={state}>
                    {state}
                </Tag>
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/details/workflow/${record.id}`}>See more details</Link>
                </Space>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data}/>;
};

export default WorkflowTable;