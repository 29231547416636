import {Card, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import type {Task} from "../../../../../../types/workflow";
import TaskConstraintsList from "../../../../../TaskConstraintsList";

interface Props {
    x: number,
    y: number,
    tid: number,
    tasks: Array<Task>
}

const NodeTooltips = (props: Props) => {
    const [task, setTask] = useState(null);

    useEffect(() => {
        if (props.tasks && !isNaN(props.tid)) {
            for (let i = 0; i < props.tasks.length; i++) {
                let task = props.tasks[i];
                if (task.id === props.tid) {
                    setTask(task);
                }
            }
        }
    }, [props.tasks]);

    const infoRow = (title: string, content) => {
        return <Row>
            <Col span={21}>{title}</Col>
            <Col span={3}>{content}</Col>
        </Row>
    };

    return <div style={{position: 'absolute', top: `${props.y}px`, left: `${props.x}px`, zIndex: 99}}>
        {task && <Card title={`Task(id=${task.id})`} bordered={false} style={{width: '300px'}}>
            {infoRow('Workflow id', task.workflowId)}
            {infoRow('Type', task.type)}
            {infoRow('Input data size', task.inputDataSize)}
            {infoRow('Computation density', task.computationDensity)}
            {infoRow('Data compress ratio', task.dataCompressRatio)}
            <h1>Constraints</h1>
            <TaskConstraintsList data={task.constraints}/>
        </Card>}
    </div>;
};

export default NodeTooltips;