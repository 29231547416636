import React, {useEffect, useRef, useState} from "react";
import G6 from "@antv/g6-pc";
import * as ReactDOM from "react-dom";
import NodeContextMenu from "./components/NodeContextMenu";
import IdInput from "./components/IdInput";
import type {Task, Workflow} from "../../../../../types/workflow";
import NodeTooltips from "./components/NodeTooltips";
import ModifyModal from "./components/ModifyModal";

interface Props {
    data: Workflow,
    onChangeId: ('root' | number, number) => void,
    onAddTask: ('root' | number) => void,
    onDeleteTask: (number) => void,
    onAddEdge: ('root' | number, number) => void,
    onSubmitModify: (Task) => void
}

const WorkflowGraph = (props: Props) => {
    const [graph, setGraph] = useState(null);
    const [selectedId: 'root' | number, setSelectedId] = useState(null);
    const [idInput: number | null, setIdInput] = useState(null);

    const [showIdInput, setShowIdInput] = useState(false);
    const [idInputX, setIdInputX] = useState(0);
    const [idInputY, setIdInputY] = useState(0);

    const [showNodeContextMenu, setShowNodeContextMenu] = useState(false);
    const [nodeContextMenuX, setNodeContextMenuX] = useState(0);
    const [nodeContextMenuY, setNodeContextMenuY] = useState(0);

    const [showNodeTooltip, setShowNodeTooltip] = useState(false);
    const [nodeTooltipX, setNodeToolTipX] = useState(0);
    const [nodeTooltipY, setNodeToolTipY] = useState(0);

    const [showModifyModal, setShowModifyModal] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        if (!props.data) {
            return;
        }

        let g = graph;
        if (!graph) {
            g = constructGraph();
            setGraph(g);
        }

        g.clear();
        g.data(transform(props.data));
        g.render();

    }, [props.data]);

    const bindEvent = (graph) => {
        graph.on('click', () => {
            setShowNodeContextMenu(false);
            setShowIdInput(false);
        });

        graph.on('node:mouseenter', evt => {
            setShowNodeContextMenu(false);
            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id === 'root') {
                return;
            }

            id = Number(id);
            setSelectedId(id);

            setNodeToolTipX(point.x);
            setNodeToolTipY(point.y);
            setShowNodeTooltip(true);
        });

        graph.on('node:mouseleave', () => {
            setShowNodeTooltip(false);
        });

        graph.on('node:dblclick', evt => {
            evt.preventDefault();

            setShowNodeTooltip(false);
            setShowNodeContextMenu(false);

            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id !== 'root') {
                id = Number(id);
            }
            setSelectedId(id);

            setIdInputX(point.x);
            setIdInputY(point.y);
            setShowIdInput(true);
        });

        graph.on('node:contextmenu', evt => {
            evt.preventDefault();

            setShowNodeTooltip(false);
            setShowIdInput(false);

            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id !== 'root') {
                id = Number(id);
            }
            setSelectedId(id);

            setNodeContextMenuX(point.x);
            setNodeContextMenuY(point.y);
            setShowNodeContextMenu(true);
        });
    };

    const constructGraph = () => {
        const container = ReactDOM.findDOMNode(ref.current);
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;
        let g = new G6.Graph({
            container: container,
            width,
            height,
            fitView: true,
            modes: {
                default: [
                    'drag-canvas',
                    'zoom-canvas',
                ],
            },
            defaultNode: {
                size: 26,
                anchorPoints: [
                    [0, 0.5],
                    [1, 0.5],
                ],
                style: {
                    fontStyle: {color: 'white'}
                }
            },
            layout: {
                type: 'dagre',
                rankdir: 'LR',
                align: 'UL',
                nodesep: 10,
                ranksep: 50,
                controlPoints: true,
            },
            defaultEdge: {
                type: 'polyline',
                size: 1,
                color: '#e2e2e2',
                style: {
                    endArrow: {
                        path: 'M 0,0 L 8,4 L 8,-4 Z',
                        fill: '#e2e2e2',
                    },
                    radius: 20,
                },
            },
        });
        g.node(function (node) {
            return {
                label: node.label,
                labelCfg: {
                    style: {
                        fill: node.id === 'root' ? '#ff9770' : '#77a6ff',
                    }
                },
            }
        });

        if (typeof window !== 'undefined') {
            window.onresize = () => {
                if (!g || g.get('destroyed')) return;
                if (!container || !container.scrollWidth || !container.scrollHeight) return;
                g.changeSize(container.scrollWidth, container.scrollHeight);
            };
        }
        bindEvent(g);

        return g;
    };

    const transform = (workflow: Workflow) => {
        let workflowId = workflow.workflowId.toString();

        let nodes = workflow.tasks.map(t => {
            let id = t.id.toString();
            return {
                id: id,
                label: id
            }
        });

        nodes.push({
            id: 'root',
            label: workflowId,
            style: {
                fill: '#ffd5b7',
                stroke: '#ffaa91'
            }
        });

        let edges = workflow.edges.map(e => {
            return {
                source: e.source.toString(),
                target: e.target.toString()
            }
        });

        return {
            nodes: nodes,
            edges: edges,
        };
    };

    const onCancel = () => {
        setShowNodeContextMenu(false);
    };

    const onAddTask = (id: string) => {
        if (id !== 'root') {
            id = Number(id);
        }
        props.onAddTask(id);
        setShowNodeContextMenu(false);
    };

    const onAddEdge = (src: 'root' | number, tgt: number) => {
        props.onAddEdge(src, Number(tgt));
        setShowNodeContextMenu(false);
    };

    const onIdInputChange = (id: string) => {
        if (id.length > 0) {
            setIdInput(Number(id));
        }
    };

    const onChangeNodeId = () => {
        if (!isNaN(idInput)) {
            props.onChangeId(selectedId, idInput);
            setIdInput(null);
        }
        setShowIdInput(false);
    };

    const onModify = (selectedId: 'root' | number) => {
        setShowModifyModal(true);
        setShowNodeContextMenu(false);
    };

    const onDelete = (selectedId: 'root' | number) => {
        setShowNodeContextMenu(false);
        props.onDeleteTask(selectedId);
    };


    const onCancelModify = () => {
        setShowModifyModal(false);
    };

    const onSubmitModify = (task: Task) => {
        props.onSubmitModify(task);
        setShowModifyModal(false);
    };

    const getTaskById = (tid: number) => {
        let tasks = props.data.tasks;
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.id === tid) {
                return task;
            }
        }
        return null;
    };

    return <div>
        <div ref={ref} style={{position: 'relative'}}/>
        {showModifyModal &&
        <ModifyModal onCancel={onCancelModify} onSubmit={onSubmitModify} task={getTaskById(selectedId)}/>}
        {showIdInput &&
        <IdInput x={idInputX} y={idInputY} onChange={onIdInputChange} value={idInput} onSubmit={onChangeNodeId}/>}
        {showNodeTooltip && <NodeTooltips x={nodeTooltipX} y={nodeTooltipY} tasks={props.data.tasks} tid={selectedId}/>}
        {showNodeContextMenu &&
        <NodeContextMenu selectedId={selectedId}
                         tasks={props.data.tasks} edges={props.data.edges}
                         x={nodeContextMenuX} y={nodeContextMenuY}
                         onCancel={onCancel}
                         onAddTask={onAddTask}
                         onAddEdge={onAddEdge}
                         onModify={onModify}
                         onDelete={onDelete}/>}
    </div>
};

export default WorkflowGraph;