import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
// import CodeEditor from "@uiw/react-textarea-code-editor";
import {Alert, Button, Space} from "antd";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

interface Props {
    onChange: (string) => void,
    defaultCode: string,
    onUpload: (any) => void,
    onClear: () => void,
}

const WorkflowCodeEditor = (props: Props, ref) => {
    const [code, setCode] = useState(props.defaultCode);
    const [err, setError] = useState(null);

    useImperativeHandle(ref, () => ({
        changeCode(code: string) {
            setCode(code);
            let [, json] = parseJson(code);
            if (json) {
                props.onChange(code, json);
            }
        }
    }));

    const parseJson = (code) => {
        try {
            let json = JSON.parse(code);
            setError(null);
            return [code, json];
        } catch (e) {
            setError(e.toString());
        }
        return [code, null];
    };

    useEffect(() => {
        parseJson(props.defaultCode);
    }, []);

    const alert = () => {
        return <div>
            {!err && <Alert message="Compile Success" type="success"/>}
            {err && <Alert message={err} type="error"/>}
        </div>
    };

    const onChange = (newCode: string) => {
        setCode(newCode);
        let [code, json] = parseJson(newCode);
        if (code && json) {
            props.onChange(code, json);
        }
    };

    const editor = () => (
        // <CodeEditor
        //     value={code}
        //     language="json"
        //     placeholder="Please enter JSON."
        //     onChange={(evn) => {
        //         let value = evn.target.value;
        //         setCode(value);
        //         let [code, json] = parseJson(value);
        //         if (code && json) {
        //             onChange(code, json);
        //         }
        //     }}
        //     padding={15}
        //     style={{
        //         fontSize: 12,
        //         backgroundColor: "#f5f5f5",
        //         fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        //     }}
        // />
        <AceEditor
            placeholder="Please enter JSON."
            mode="json"
            theme="github"
            name="blah2"
            onLoad={() => {
            }}
            style={{
                width: '100%'
            }}
            onChange={onChange}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={code}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
            }}/>
    );

    const clear = () => {
        props.onClear();
    };

    const upload = () => {
        let [, json] = parseJson(code);
        if (json) {
            props.onUpload(json);
        }
    };

    const prettify = () => {
        try {
            let json = JSON.parse(code);
            let prettier = JSON.stringify(json, null, 2);
            setCode(prettier);
            props.onChange(prettier, json);
        } catch (e) {
            console.log(e);
        }
    };

    return <Space direction="vertical" size="middle" style={{display: 'flex'}}>
        {alert()}
        {editor()}
        <Space direction="horizontal" size="middle" style={{display: 'flex'}}>
            <Button onClick={upload}>Upload</Button>
            <Button onClick={prettify}>Prettify</Button>
            <Button danger onClick={clear}>Clear</Button>
        </Space>
    </Space>;
};

export default forwardRef(WorkflowCodeEditor);