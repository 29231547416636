import React from 'react';
import DashboardLayout from "../../layout/DashboardLayout";
import WorkflowConsole from "../../components/Console/Workflow/WorkflowConsole";

const WorkflowConsoleView = () => {
    const content = <WorkflowConsole/>;

    const title = "Workflow Console";

    return <DashboardLayout section={'WorkflowConsole'} title={title} content={content}/>
};

export default WorkflowConsoleView;
