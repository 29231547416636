import React, {useEffect, useRef, useState} from "react";
import G6 from "@antv/g6-pc";
import * as ReactDOM from "react-dom";
import NodeContextMenu from "./components/NodeContextMenu";


const NodeTopography = (props) => {
    const [graph, setGraph] = useState(null);
    const [nid, setNid] = useState(0);

    const [showNodeContextMenu, setShowNodeContextMenu] = useState(false);
    const [nodeContextMenuX, setNodeContextMenuX] = useState(0);
    const [nodeContextMenuY, setNodeContextMenuY] = useState(0);

    const ref = useRef(null);

    const refreshDraggedNodePosition = e => {
        const model = e.item.get('model');
        model.fx = e.x;
        model.fy = e.y;
    };

    const handleNodeDoubleClick = (e, graph) => {
        const item = e.item;
        // animately move the graph to focus on the item.
        // the second parameter controlls whether move with animation, the third parameter is the animate configuration
        graph.focusItem(item, true, {
            easing: 'easeCubic',
            duration: 500,
        });
    };

    const bindEvent = (graph) => {
        graph.on('click', () => {
            setShowNodeContextMenu(false);
        });

        graph.on('node:dragstart', (e) => {
            graph.layout();
            refreshDraggedNodePosition(e);
        });

        graph.on('node:drag', (e) => {
            refreshDraggedNodePosition(e);
        });

        graph.on('node:dblclick', (e) => {
            e.preventDefault();
            handleNodeDoubleClick(e, graph);
        });

        graph.on('node:contextmenu', evt => {
            evt.preventDefault();
            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id === 'root') {
                return;
            }

            setNid(Number(id));
            setNodeContextMenuX(point.x);
            setNodeContextMenuY(point.y);
            setShowNodeContextMenu(true);
        });
    };

    const constructGraph = () => {
        const container = ReactDOM.findDOMNode(ref.current);
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;
        const g = new G6.Graph({
            container: container,
            width,
            height,
            fitView: true,
            layout: {
                type: 'force',
                preventOverlap: true,
                nodeSize: 20,
            },
            modes: {
                default: ['drag-node', 'zoom-canvas'],
            },
            defaultNode: {
                size: 20,
            },
        });

        if (typeof window !== 'undefined') {
            window.onresize = () => {
                if (!g || g.get('destroyed')) return;
                if (!container || !container.scrollWidth || !container.scrollHeight) return;
                g.changeSize(container.scrollWidth, container.scrollHeight);
            };
        }
        bindEvent(g);
        return g;
    };

    useEffect(() => {
        if (!props.data) {
            return;
        }
        let g = graph;
        if (!graph) {
            g = constructGraph();
            setGraph(g);
        }

        g.clear();

        g.data(props.data);
        g.render();

    }, [props.data]);

    return <div ref={ref} style={{position: 'relative'}}>
        {showNodeContextMenu && <NodeContextMenu nid={nid} x={nodeContextMenuX} y={nodeContextMenuY} onCancel={() => {
            setShowNodeContextMenu(false);
        }}/>}
    </div>
};

export default NodeTopography;