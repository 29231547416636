import React from "react";
import TaskQueueChart from "../../Charts/TaskQueueChart";
import SystemStatusChart from "../../Charts/SystemStatusChart";
import {Row, Col} from "antd";
import TaskTypePieChart from "../../Charts/TaskTypePieChart";

const StatusPanel = () => {
    const taskQueueData = Array.from(new Array(30).keys()).map(i => {
            return {
                length: Math.ceil(180.8 * i),
                node: `Node(id=${i % 9})`,
                type: `Task(id=${i})`
            }
        }
    );

    const taskTypeData = [
        {
            type: 'Photographing',
            value: 27,
        },
        {
            type: 'Computation',
            value: 25,
        },
        {
            type: 'Observation',
            value: 25,
        },
    ];

    const fetchData = () => {
        const time = new Date().toLocaleTimeString();
        return [{
            "time": time,
            "value": Math.round(Math.random() * 100),
            "category": "Rest tasks"
        }, {
            "time": time,
            "value": Math.round(Math.random() * 100),
            "category": "Workflows"
        }, {
            "time": time,
            "value": Math.round(Math.random() * 100),
            "category": "Schedule queue"
        }]
    };

    return <div style={{height: '100vh'}}>
        <TaskQueueChart data={taskQueueData}/>
        <Row>
            <Col span={12}>
                <SystemStatusChart
                    interval={2000}
                    maxLen={10}
                    categories={3}
                    fetchData={fetchData}/>
            </Col>
            <Col span={12}>
                <TaskTypePieChart data={taskTypeData}/>
            </Col>
        </Row>
    </div>
};

export default StatusPanel;