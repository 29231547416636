import React from 'react';
import {ConsoleSqlOutlined, GlobalOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import {Link} from "react-router-dom";

const {Header, Sider, Content} = Layout;

const DashboardLayout = (props) => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const navItems = ['Dashboard'].map((title) => ({
        key: title,
        label: title,
    }));

    const sidebarItems = [
        {
            key: 'Browser',
            icon: React.createElement(GlobalOutlined),
            label: <Link to={'/browser'}>Browser</Link>,
        },
        {
            key: 'Console',
            icon: React.createElement(ConsoleSqlOutlined),
            label: 'Console',
            children: [
                {
                    key: 'StatusPanel',
                    label: <Link to={'/console/status'}>Status Panel</Link>,
                },
                {
                    key: 'WorkflowConsole',
                    label: <Link to={'/console/workflow'}>Workflow</Link>,
                }
            ]
        },
        {
            key: 'Details',
            icon: React.createElement(InfoCircleOutlined),
            label: 'Details',
            children: [
                {
                    key: "Node",
                    label: <Link to={'/details/node/index'}>Node</Link>,
                },
                {
                    key: "Workflow",
                    label: <Link to={'/details/workflow/index'}>Workflow</Link>,
                },
                {
                    key: "Task",
                    label: <Link to={'/details/task/index'}>Task</Link>,
                },
            ]
        },
    ];

    return (
        <Layout>
            <Header className="header">
                <div style={{
                    width: "120px",
                    height: "31px",
                    background: "rgba(255, 255, 255, 0.2)",
                    margin: "16px 28px 16px 0",
                    float: "left",
                }}/>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['Dashboard']} items={navItems}/>
            </Header>
            <Layout>
                <Sider
                    width={200}
                    style={{
                        background: colorBgContainer,
                    }}
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[props.section]}
                        defaultOpenKeys={['Details', 'Console']}
                        style={{
                            height: '100%',
                            borderRight: 0,
                        }}
                        items={sidebarItems}
                    />
                </Sider>
                <Layout
                    style={{
                        padding: '0 24px 24px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                    >
                        <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            background: colorBgContainer,
                        }}
                    >
                        {props.content}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default DashboardLayout;