import React, {useEffect, useState} from 'react';
import {Space, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link} from "react-router-dom";
import {colorMap} from "../../../helper/colormap";
import Progress from "antd/es/progress";

interface DataType {
    key: string;
    id: number;
    workflowId: number;
    type: number;
    nodeId: number,
    inputDataSize: number,
    computationDensity: number,
    dataCompressRatio: number,
    percent: number,
    state: 'FAIL' | 'RUNNING' | 'PENDING';
}

const TaskTable = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data]);

    const formatPercent = (percent: number) => {
        if (percent >= 0 && percent <= 1) {
            return percent * 100;
        }
        return Math.min(100, Math.max(percent, 0));
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Workflow Id',
            dataIndex: 'workflowId',
            key: 'workflowId',
            render: (_, record) => (
                <Link to={`/details/workflow/${record.workflowId}`}>{record.workflowId}</Link>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Node Id',
            dataIndex: 'nodeId',
            key: 'nodeId',
            render: (_, record) => {
                return <div>
                    {record.nodeId && <Link to={`/details/node/${record.nodeId}`}>{record.nodeId}</Link>}
                    {!record.nodeId && <Tag color={'volcano'}>NOT ASSIGNED</Tag>}
                </div>
            },
        },
        {
            title: 'Input Data Size',
            dataIndex: 'inputDataSize',
            key: 'inputDataSize',
        },
        {
            title: 'Computation Density',
            dataIndex: 'computationDensity',
            key: 'computationDensity',
        },
        {
            title: 'Data Compress Ratio',
            dataIndex: 'dataCompressRatio',
            key: 'dataCompressRatio',
        },
        {
            title: 'Percent',
            dataIndex: 'percent',
            key: 'percent',
            render: (_, record) => {
                return <Progress percent={formatPercent(record.percent)}/>
            }
        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            render: (_, record) => {
                let state = record.state;
                let color = colorMap[state];
                return <Tag color={color} key={state}>
                    {state}
                </Tag>
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/details/task/${record.id}`}>See more details</Link>
                </Space>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data}/>;
};

export default TaskTable;