import React, {useEffect, useRef} from 'react';
import {Input, Tooltip} from 'antd';

interface NumericInputProps {
    style?: React.CSSProperties;
    x: number,
    y: number,
    value: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
}

const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

const IdInput = (props: NumericInputProps) => {
    const {value, onChange} = props;

    const inputRef = useRef(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            onChange(inputValue);
        }
    };

    useEffect(()=>{
        inputRef.current.focus();
    }, []);

    // '.' at the end or only '-' in the input box.
    const handleBlur = () => {
        if (!value || !value.length) {
            return;
        }
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.' || value === '-') {
            valueTemp = value.slice(0, -1);
        }
        onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    };

    const title = value ? (
        <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
    ) : (
        'Input a number'
    );

    return (
        <div style={{width: 120, position: 'absolute', left: props.x - 60, top: props.y - 16}}>
            <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
                <Input
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    onPressEnter={props.onSubmit}
                    onBlur={handleBlur}
                    placeholder="Input a number"
                    maxLength={16}
                />
            </Tooltip>
        </div>
    );
};

export default IdInput;