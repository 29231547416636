import React from 'react';
import DashboardLayout from "../../layout/DashboardLayout";
import WorkflowDetails from "../../components/WorkflowDetails/WorkflowDetails";

const WorkflowView = () => {
    const content = <WorkflowDetails/>;

    const title = "Workflow";

    return <DashboardLayout section={'Workflow'} title={title} content={content}/>
};

export default WorkflowView;
