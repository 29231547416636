import React, {useEffect, useRef, useState} from "react";
import G6 from "@antv/g6-pc";
import * as ReactDOM from "react-dom";
import NodeContextMenu from "./components/NodeContextMenu";
import NodeTooltips from "./components/NodeTooltips";
import {getTaskBrief} from "../../../../api/api";


const WorkflowGraph = (props) => {
    const [graph, setGraph] = useState(null);
    const [tid, setTid] = useState(0);

    const [brief, setBrief] = useState(null);

    const [showNodeContextMenu, setShowNodeContextMenu] = useState(false);
    const [nodeContextMenuX, setNodeContextMenuX] = useState(0);
    const [nodeContextMenuY, setNodeContextMenuY] = useState(0);

    const [showNodeTooltip, setShowNodeTooltip] = useState(false);
    const [nodeTooltipX, setNodeToolTipX] = useState(0);
    const [nodeTooltipY, setNodeToolTipY] = useState(0);

    const ref = useRef(null);

    const bindEvent = (graph) => {
        graph.on('node:mouseenter', evt => {
            setShowNodeContextMenu(false);
            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id === 'root') {
                return;
            }

            getTaskBrief(id, (res) => {
                setBrief(res.data);
            });

            setNodeToolTipX(point.x);
            setNodeToolTipY(point.y);
            setShowNodeTooltip(true);
        });

        graph.on('click', () => {
            setShowNodeContextMenu(false);
        });

        graph.on('node:mouseleave', () => {
            setShowNodeTooltip(false);
        });

        graph.on('node:contextmenu', evt => {
            setShowNodeTooltip(false);
            evt.preventDefault();
            const {item} = evt;
            const model = item.getModel();
            const {x, y} = model;
            const point = graph.getCanvasByPoint(x, y);

            let id = evt.item._cfg.id;
            if (id === 'root') {
                return;
            }

            setTid(Number(id));
            setNodeContextMenuX(point.x);
            setNodeContextMenuY(point.y);
            setShowNodeContextMenu(true);
        });
    };

    const constructGraph = () => {
        const container = ReactDOM.findDOMNode(ref.current);
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;
        let g = new G6.Graph({
            container: container,
            width,
            height,
            fitView: true,
            modes: {
                default: [
                    'drag-canvas',
                    'zoom-canvas',
                ],
            },
            defaultNode: {
                size: 26,
                anchorPoints: [
                    [0, 0.5],
                    [1, 0.5],
                ],
                style: {
                    fontStyle: {color: 'white'}
                }
            },
            layout: {
                type: 'dagre',
                rankdir: 'LR',
                align: 'UL',
                nodesep: 10,
                ranksep: 50,
                controlPoints: true,
            },
            defaultEdge: {
                type: 'polyline',
                size: 1,
                color: '#e2e2e2',
                style: {
                    endArrow: {
                        path: 'M 0,0 L 8,4 L 8,-4 Z',
                        fill: '#e2e2e2',
                    },
                    radius: 20,
                },
            },
        });
        g.node(function (node) {
            return node.id !== 'root' ? {
                label: node.id,
                labelCfg: {
                    style: {
                        fill: '#77a6ff',
                    }
                },
            } : {
                label: node.label,
                labelCfg: {
                    offset: 10,
                    position: 'top',
                },
            };
        });

        if (typeof window !== 'undefined') {
            window.onresize = () => {
                if (!g || g.get('destroyed')) return;
                if (!container || !container.scrollWidth || !container.scrollHeight) return;
                g.changeSize(container.scrollWidth, container.scrollHeight);
            };
        }
        bindEvent(g);

        return g;
    };

    useEffect(() => {
        if (!props.data) {
            return;
        }
        let g = graph;
        if (!graph) {
            g = constructGraph();
            setGraph(g);
        }

        g.clear();

        g.data(props.data);
        g.render();

    }, [props.data]);

    return <div ref={ref} style={{position: 'relative'}}>
        {showNodeTooltip && <NodeTooltips x={nodeTooltipX} y={nodeTooltipY} brief={brief}/>}
        {showNodeContextMenu && <NodeContextMenu tid={tid} x={nodeContextMenuX} y={nodeContextMenuY} onCancel={() => {
            setShowNodeContextMenu(false);
        }}/>}
    </div>
};

export default WorkflowGraph;