import React, {useEffect, useState} from 'react';
import {Descriptions, Tag} from 'antd';
import {Link} from "react-router-dom";
import Progress from "antd/es/progress";

interface Info {
    id: number;
    numTasks: number;
    type: number;
    cpuFreq: number,
    state: 'HEALTHY' | 'DISCONNECTED'
}

interface Props {
    info: Info
}

const NodeInfoTable = (props: Props) => {
    const [data, setData] = useState(null);

    const stateTag = (state: 'HEALTHY' | 'DISCONNECTED') => {
        let color = state === 'HEALTHY' ? 'green' : 'volcano';
        return <Tag color={color} key={state}>
            {state}
        </Tag>
    };

    useEffect(() => {
        if (props.info) {
            setData(props.info);
        }
    }, [props.info]);

    return <div>
        {data &&
        <Descriptions title="Node Info" layout="vertical" bordered
                      column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}>
            <Descriptions.Item label="Id">{data.id}</Descriptions.Item>
            <Descriptions.Item label="Number of Tasks">{data.numTasks}</Descriptions.Item>
            <Descriptions.Item label="CPU Frequency">{data.cpuFreq}</Descriptions.Item>
            <Descriptions.Item label="State">
                {stateTag(data.state)}
            </Descriptions.Item>
        </Descriptions>}
    </div>;
};

export default NodeInfoTable;