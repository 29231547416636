import React, {Component} from "react";
import {Engine} from "./components/Engine";
import * as qs from 'query-string';
import data from '../../assets/active.txt'
import Highlights from "./components/Highlights";
import Search from "./components/Search/Search";
import Info from "./components/Info";
import "../../assets/theme.css"
import SelectedStations from "./components/Selection/SelectedStations";

// Some config
const UseDateSlider = false;
const DateSliderRangeInMilliseconds = 24 * 60 * 60 * 1000;  // 24 hours

// Bypass CORS
function getCorsFreeUrl(url) {
    return 'https://api.allorigins.win/raw?url=' + url;
}

export default class Browser extends Component {
    state = {
        selected: [],
        stations: [],
        query: null,
        queryObjectCount: 0,
        initialDate: new Date().getTime(),
        currentDate: new Date().getTime(),
        referenceFrame: UseDateSlider ? 2 : 1
    };

    componentDidMount() {
        this.engine = new Engine();
        this.engine.referenceFrame = this.state.referenceFrame;
        this.engine.initialize(this.el, {
            onStationClicked: this.handleStationClicked
        });
        this.addStations();

        this.engine.updateAllPositions(new Date());

        setInterval(this.handleTimer, 1000);
    }

    componentWillUnmount() {
        this.engine.dispose();
    }

    processQuery = (stations) => {
        const q = window.location.search;
        if (!q) return;

        const params = qs.default.parse(q);

        if (params.ss) {
            const selectedIds = params.ss.split(',');
            if (!selectedIds || selectedIds.length === 0) return;

            selectedIds.forEach(id => {
                const station = this.findStationById(stations, id);
                if (station) this.selectStation(station);
            });
        }

        if (params.highlight) {
            const query = params.highlight;
            const matches = this.queryStationsByName(stations, query);
            matches.forEach(st => this.engine.highlightStation(st));
            this.setState({...this.state, query, queryObjectCount: matches.length});
        }
    };

    queryStationsByName = (stations, query) => {
        query = query.toLowerCase();
        return stations.filter(st => st.name.toLowerCase().indexOf(query) > -1)
    };

    findStationById = (stations, id) => {
        return stations.find(st => st.satrec && st.satrec.satnum === id);
    };

    handleStationClicked = (station) => {
        if (!station) return;

        this.toggleSelection(station);
    };

    toggleSelection(station) {
        if (this.isSelected(station))
            this.deselectStation(station);
        else
            this.selectStation(station);
    }

    isSelected = (station) => {
        return this.state.selected.includes(station);
    };

    selectStation = (station) => {
        const newSelected = this.state.selected.concat(station);
        this.setState({selected: newSelected});

        this.engine.addOrbit(station);
    };

    deselectStation = (station) => {
        const newSelected = this.state.selected.filter(s => s !== station);
        this.setState({selected: newSelected});

        this.engine.removeOrbit(station);
    };

    addStations = () => {
        this.loadFromLocal();
    };

    loadFromLocal = () => {
        this.engine.loadTleFileStations(data, 0xffffff)
            .then(stations => {
                this.setState({stations});
                this.processQuery(stations);
            });
    };

    addCelestrakSets = () => {
        this.engine.loadTleFileStations(getCorsFreeUrl('http://www.celestrak.org/NORAD/elements/active.txt'), 0xffffff)
            .then(stations => {
                this.setState({stations});
                this.processQuery(stations);
            });
    };

    handleTimer = () => {
        // By default, update in realtime every second, unless dateSlider is displayed.
        if (!UseDateSlider) this.handleDateChange(null, new Date());
    };

    handleSearchResultClick = (station) => {
        if (!station) return;

        this.toggleSelection(station);
    };

    handleRemoveSelected = (station) => {
        if (!station) return;

        this.deselectStation(station);
    };

    handleRemoveAllSelected = () => {
        this.state.selected.forEach(s => this.engine.removeOrbit(s));
        this.setState({selected: []});
    };

    handleReferenceFrameChange = () => {
        this.state.selected.forEach(s => this.engine.removeOrbit(s));

        const newType = this.state.referenceFrame === 1 ? 2 : 1;
        this.setState({referenceFrame: newType});
        this.engine.setReferenceFrame(newType);

        this.state.selected.forEach(s => this.engine.addOrbit(s));
    };

    handleDateChange = (v, d) => {
        const newDate = v ? v.target.value : d;
        this.setState({currentDate: newDate});

        const date = new Date();
        date.setTime(newDate);
        this.engine.updateAllPositions(date);
    };

    renderDate = (v) => {
        const result = new Date();
        result.setTime(v);
        return result.toString();
    };

    render() {
        const {selected, stations, initialDate, currentDate} = this.state;

        const maxMs = initialDate + DateSliderRangeInMilliseconds;

        return (
            <div style={{height: "100vh", position: 'relative', overflow: "hidden"}}>
                <Info stations={stations} refMode={this.state.referenceFrame}/>
                <Search stations={this.state.stations} onResultClick={this.handleSearchResultClick}/>
                <SelectedStations selected={selected} onRemoveStation={this.handleRemoveSelected}
                                  onRemoveAll={this.handleRemoveAllSelected}/>
                <div ref={c => this.el = c}
                     style={{position: 'absolute', width: '100%', top: '0px', height: '100%'}}/>
            </div>
        )
    }
}