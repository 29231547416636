import React from 'react';
import Browser from "../components/Browser/Browser";
import DashboardLayout from "../layout/DashboardLayout";

const BrowserView = () => {
    const content = (
        <Browser/>
    );

    const title = "3d Browser";

    return <DashboardLayout section={'Browser'} title={title} content={content}/>
};

export default BrowserView;
