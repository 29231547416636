import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BrowserView from "../views/BrowserView";
import {Navigate} from "react-router";
import StatusView from "../views/console/StatusView";
import WorkflowView from "../views/details/WorkflowView";
import TaskView from "../views/details/TaskView";
import NodeView from "../views/NodeView";
import WorkflowConsoleView from "../views/console/WorkflowConsoleView";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/browser'} element={<BrowserView/>}/>
                <Route path={'/console/status'} element={<StatusView/>}/>
                <Route path={'/console/workflow'} element={<WorkflowConsoleView/>}/>
                <Route path={'/details/node/:id'} element={<NodeView/>}/>
                <Route path={'/details/workflow/:id'} element={<WorkflowView/>}/>
                <Route path={'/details/task/:id'} element={<TaskView/>}/>
                <Route path="*" element={<Navigate to="/browser"/>}/>
            </Routes>
        </BrowserRouter>
    )
}