import React, {useEffect, useRef, useState} from "react";
import {Col, message, Row} from "antd";
import WorkflowGraph from "./components/Graph/WorkflowGraph";
import WorkflowCodeEditor from "./components/Editor/WorkflowCodeEditor";
import type {Task, Workflow} from "../../../types/workflow";
import {getAvailableWorkflowId, isAvailableTaskId, scheduleSingleWorkflow} from "../../../api/api";

const WorkflowConsole = () => {
    const [defaultCode, setDefaultCode] = useState(null);

    const [workflow: Workflow | null, setWorkflow] = useState(null);
    const [code, setCode] = useState(defaultCode);

    const [messageApi, contextHolder] = message.useMessage();

    const editor = useRef(null);

    const error = (content) => {
        messageApi.open({
            type: 'error',
            content: content,
        }).then(null);
    };

    const success = (content) => {
        messageApi.open({
            type: 'success',
            content: content,
        }).then(null);
    };

    const changeCode = (code: string) => {
        editor.current.changeCode(code);
    };

    const existsNode = (nid: Number) => {
        if (!workflow) {
            return false;
        }

        let tasks = workflow.tasks;
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.id === nid) {
                return true;
            }
        }
        return false;
    };

    const availableTaskId = async (): number => {
        let taskId = 1;
        while (existsNode(taskId) || !await isAvailableTaskId(taskId)) {
            taskId++;
        }
        return taskId;
    };

    const getDefaultCode = () => {
        getAvailableWorkflowId((res) => {
            let defaultCode = `{
  "workflowId": ${res.data},
  "tasks": [],
  "edges": []
}`;
            parseCode(defaultCode, JSON.parse(defaultCode));
            setDefaultCode(defaultCode);
        }, (err) => {
            error(err);
        });
    };

    useEffect(() => {
        getDefaultCode();
    }, []);

    const isValidate = (workflow: Workflow) => {
        if (isNaN(workflow.workflowId) || !workflow.tasks || !workflow.edges) {
            return false;
        }

        for (let i = 0; i < workflow.edges.length; i++) {
            let edge = workflow.edges[i];
            if (!(edge.source === 'root' || !isNaN(edge.source)) || isNaN(edge.target)) {
                return false;
            }
        }

        for (let i = 0; i < workflow.tasks.length; i++) {
            let task = workflow.tasks[i];
            if (isNaN(task.id)) {
                return false;
            }
        }

        return true;
    };

    const parseCode = (code, json: any) => {
        if (!json) return;
        let wf: Workflow = json;
        if (!isValidate(wf)) return;
        setCode(code);
        setWorkflow(wf);
    };

    const addTaskAsync = async (selectedId: 'root' | number) => {
        let workflow: Workflow = JSON.parse(code);
        let tid = await availableTaskId();
        workflow.tasks.push({
            id: tid,
            workflowId: workflow.workflowId,
            type: 0,
            inputDataSize: 0,
            computationDensity: 0,
            dataCompressRatio: 0,
            constraints: []
        });
        workflow.edges.push({
            source: selectedId,
            target: tid,
        });
        success(`Add new task(id=${tid})`);
        changeCode(JSON.stringify(workflow, null, 2));
    };

    const onAddTask = (selectedId: 'root' | number) => {
        addTaskAsync(selectedId).then(() => {
        });
    };

    const onAddEdge = (src: 'root' | number, tgt: number) => {
        let workflow: Workflow = JSON.parse(code);
        workflow.edges.push({
            source: src,
            target: tgt
        });
        success(`Add new edge: Task(id=${src}) -> Task(id=${tgt})`);
        changeCode(JSON.stringify(workflow, null, 2));
    };

    const changeIdAsync = async (selectedId: 'root' | number, newId: number) => {
        if (selectedId.toString() === newId.toString()) {
            return;
        }

        let workflow: Workflow = JSON.parse(code);
        if (selectedId === 'root') {
            success(`Set workflow id from ${workflow.workflowId} to ${newId}`);

            workflow.tasks.forEach(t => {
                t.workflowId = newId;
            });

            workflow.workflowId = newId;
        } else {
            for (let i = 0; i < workflow.tasks.length; i++) {
                if (workflow.tasks[i].id === newId) {
                    error(`Task(id=${newId}) already exists!`);
                    return;
                }
            }

            if (!await isAvailableTaskId(newId)) {
                error(`Task(id=${newId}) already exists(in system)!`);
                return;
            }

            workflow.tasks.map(t => {
                if (t.id === selectedId) {
                    t.id = newId;
                }
            });

            workflow.edges.map(e => {
                if (e.source === selectedId) {
                    e.source = newId;
                } else if (e.target === selectedId) {
                    e.target = newId;
                }
            });

            success(`Set task's id from ${selectedId} to ${newId}`);
        }
        changeCode(JSON.stringify(workflow, null, 2));
    };

    const onChangeId = (selectedId: 'root' | number, newId: number) => {
        changeIdAsync(selectedId, newId).then(() => {
        });
    };

    const onSubmitModify = (task: Task) => {
        let workflow: Workflow = JSON.parse(code);
        for (let i = 0; i < workflow.tasks.length; i++) {
            if (workflow.tasks[i].id === task.id) {
                workflow.tasks[i] = task;
                changeCode(JSON.stringify(workflow, null, 2));
                return;
            }
        }
    };

    const onDeleteTask = (taskId: number) => {
        let workflow: Workflow = JSON.parse(code);
        workflow.tasks = workflow.tasks.filter(t => t.id !== taskId);
        workflow.edges = workflow.edges.filter(e => e.source !== taskId && e.target !== taskId);
        changeCode(JSON.stringify(workflow, null, 2));
    };

    const onUpload = (json: Workflow) => {
        let workflow = {
            id: json.workflowId,
            tasks: json.tasks.map(t => ({
                ...t,
                id: null,
                taskId: t.id,
                computeDensity: t.computationDensity
            })),
            edgesList: json.edges.map(e => ({
                sourceId: e.source,
                targetId: e.target
            })).filter(e => e.sourceId !== 'root'),
        };
        scheduleSingleWorkflow(workflow, () => {
        });
    };

    const onClear = () => {
        getAvailableWorkflowId((res) => {
            let defaultCode = `{
  "workflowId": ${res.data},
  "tasks": [],
  "edges": []
}`;
            parseCode(defaultCode, JSON.parse(defaultCode));
            setDefaultCode(defaultCode);
            changeCode(defaultCode);
        }, (err) => {
            error(err);
        });
    };

    return <Row justify={'space-between'} style={{
        WebkitUserSelect: 'none',
        msUserSelect: 'none'
    }}>
        {contextHolder}
        <Col span={11}>
            <WorkflowGraph data={workflow} onAddTask={onAddTask} onAddEdge={onAddEdge} onChangeId={onChangeId}
                           onSubmitModify={onSubmitModify} onDeleteTask={onDeleteTask}/>
        </Col>
        <Col span={12}>
            {defaultCode &&
            <WorkflowCodeEditor ref={editor} onChange={parseCode} onUpload={onUpload} onClear={onClear}
                                defaultCode={defaultCode}/>}
        </Col>
    </Row>
};

export default WorkflowConsole;