import React from 'react';
import DashboardLayout from "../../layout/DashboardLayout";
import TaskDetails from "../../components/TaskDetails/TaskDetails";

const TaskView = () => {
    const content = <TaskDetails/>;

    const title = "Task";

    return <DashboardLayout section={'Task'} title={title} content={content}/>
};

export default TaskView;
