import {List} from "antd";
import React, {useEffect, useState} from "react";

const TaskConstraintsList = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data.map((c) => {
            return <span><b>{c.attribute}</b>{` ${c.op ? c.op : ''} ${c.value}, args: ${c.args ? c.args : 'None'}`}</span>;
        }));
    }, [props.data]);

    return <List
        size="small"
        bordered
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
    />;
};

export default TaskConstraintsList;