import {PREFIX} from "./constants";
import {asyncGet, get, post} from "./ajax";
import type {Workflow} from "../types/workflow";

export function getWorkflowDetails(id: number, callback, onError?) {
    const url = `${PREFIX}/workflows/details/${id}`;
    get(url, callback, onError);
}

export function getAllWorkflowInfo(callback, onError?) {
    const url = `${PREFIX}/workflows/info`;
    get(url, callback, onError);
}

export function getTaskBrief(tid: number, callback, onError?) {
    const url = `${PREFIX}/tasks/brief/${tid}`;
    get(url, callback, onError);
}

export function getAllTaskInfo(callback, onError?) {
    const url = `${PREFIX}/tasks/info`;
    get(url, callback, onError);
}

export function getTaskDetails(id: number, callback, onError?) {
    const url = `${PREFIX}/tasks/details/${id}`;
    get(url, callback, onError);
}

export function getNodeDetails(id: number, callback, onError?) {
    const url = `${PREFIX}/nodes/details/${id}`;
    get(url, callback, onError);
}

export function getAllNodeInfo(callback, onError?) {
    const url = `${PREFIX}/nodes/info`;
    get(url, callback, onError);
}

export function getNodeTopo(callback, onError?) {
    const url = `${PREFIX}/nodes/topo`;
    get(url, callback, onError);
}

export function getAvailableTaskId(callback, onError?) {
    const url = `${PREFIX}/tasks/availableId`;
    get(url, callback, onError);
}

export function getAvailableWorkflowId(callback, onError?) {
    const url = `${PREFIX}/workflows/availableId`;
    get(url, callback, onError);
}

export async function isAvailableTaskId(taskId: number, callback, onError?) {
    const url = `${PREFIX}/tasks/availableId/${taskId}`;
    let res = await asyncGet(url, callback, onError);
    return res.code === 200;
}

export async function isAvailableWorkflowId(workflowId: number, callback, onError?) {
    const url = `${PREFIX}/workflows/availableId/${workflowId}`;
    let res = await asyncGet(url, callback, onError);
    return res.code === 200;
}

export function scheduleSingleWorkflow(workflow: Workflow, callback, onError?) {
    const url = `${PREFIX}/workflow`;
    post(url, workflow, callback, onError);
}


