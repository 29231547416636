import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/plots';

const SystemStatusChart = (props) => {
    let [data, setData] = useState([]);

    useEffect(() => {
        setInterval(() => {
            const newData = props.fetchData();
            const categories = props.categories;
            const maxLen = props.maxLen;
            const len = data.length / categories;
            if (len === maxLen) {
                data = data.slice(categories, data.length);
            }
            data = [...data, ...newData];
            setData(data);
        }, props.interval)
    }, []);

    const config = {
        data,
        xField: 'time',
        yField: 'value',
        seriesField: 'category',
    };

    return <div>
        <h1>System Status</h1>
        <Line {...config} onlyChangeData={true}/>
    </div>;
};

export default SystemStatusChart;

