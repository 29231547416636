import React from 'react';
import DashboardLayout from "../../layout/DashboardLayout";
import StatusPanel from "../../components/Console/StatusPanel/StatusPanel";

const StatusView = () => {
    const content = <StatusPanel/>;

    const title = "Status Panel";

    return <DashboardLayout section={'StatusPanel'} title={title} content={content}/>
};

export default StatusView;
