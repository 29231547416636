import React, {useEffect, useState} from 'react';
import {Space, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link} from "react-router-dom";

interface DataType {
    key: string;
    id: number;
    numTasks: number,
    cpuFreq: number,
    state: 'HEALTHY' | 'DISCONNECTED'
}

const NodeTable = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            render: (_, record) => {
                let state = record.state;
                let color = state === 'HEALTHY' ? 'green' : 'volcano';
                return <Tag color={color} key={state}>
                    {state}
                </Tag>
            }
        },
        {
            title: 'Number of Tasks',
            dataIndex: 'numTasks',
            key: 'numTasks',
        },
        {
            title: 'CPU Frequency',
            dataIndex: 'cpuFreq',
            key: 'cpuFreq',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/details/node/${record.id}`}>See more details</Link>
                </Space>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data}/>;
};

export default NodeTable;